import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

import { Globals } from '../../classes';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-connect-stripe',
  templateUrl: './connect-stripe.component.html',
  styleUrls: ['./connect-stripe.component.scss']
})
export class ConnectStripeComponent implements OnInit {
  @Input() page: 'become-a-seller' | 'seller/payment';
  @Input() existingId: string;
  @Output() authCode: EventEmitter<string> = new EventEmitter();

  connectStripeImg = 'assets/img/connect-stripe.png';
  stripeLoginLink: SafeUrl;

  constructor(
    private http: HttpClient
  ) { }

  async ngOnInit() {}

  public async goToStripeAcct(stripeId: string) {
    const data: { url: string; } = await this.http.get(`${Globals.environment.apiUrl}seller/stripe/login-link/${this.existingId}`).toPromise() as any;
      if (data && data.url) {
        window.open(data.url, '_blank');
      }
  }

  public registerStripe() {
    const redirectUri = this.page === 'become-a-seller' ? Globals.environment.stripe.redirectUris.sellerAccountCreated : Globals.environment.stripe.redirectUris.existingSellerRegistered;
    const url = `https://connect.stripe.com/express/oauth/authorize?redirect_uri=${redirectUri}&client_id=${Globals.environment.stripe.clientId}&state=${Globals.user.id}`;
    location.href = url;
  }

}

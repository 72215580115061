<ng-container *ngIf="!!o">
  <div [id]="o.id" class="mat-title text-left">Buyer</div>
  <div class="text-left">
    <mat-card-title>
      {{o.shipToEmail}}
    </mat-card-title>
    <p *ngIf="!!o.buyer?.id">User ID: <b>{{o.buyer.id}}</b></p>

    <h3 class="mat-title">Items</h3>
    <a
      target="_blank"
      *ngFor="let item of o.items"
      [href]="url + '/product/' + item.listingId"
      class="order-item"
    >
      <h4>{{item.listing.name}} ({{item.qty}}) ${{item.purchasedPrice | number: '1.2-2'}} ea</h4>
      <img [src]="item.listing.thumbnailImg">
    </a>

    <div class="totals">
      <div>Order total: ${{o.totalsBilled.totalWithShippingAndTax | number: '1.2-2'}}</div>
      <div>Shipping: ${{o.totalsBilled.consumerShipping | number: '1.2-2'}}</div>
      <div>Taxes: ${{o.totalsBilled.tax | number: '1.2-2'}}</div>
    </div>


    <div *ngIf="o.shipments" style="max-width: 225px; display: inline-block;">
      <div><b>Shipping to:</b></div>
      <span [innerHtml]="o.shipments[0].address_to | streetAdress"></span>
    </div>
  </div>
  <br><br>
  <ng-container *ngIf="o.labels && o.labels.length > 0">
    <div class="mat-title text-left">Packages ({{o.labels.length}})</div>
  <mat-card  class="text-left" *ngFor="let l of o.labels">
    <mat-card-subtitle>Shippo ID:</mat-card-subtitle>
    <mat-card-title>
      {{l.object_id}}
    </mat-card-title>
    <br>
    <div style="max-width: 225px; display: inline-block;">
      <div><b>Shipping from:</b></div>
      <span [innerHtml]="l.shipment.address_from | streetAdress"></span>
    </div>
    <br>
    <br>
    <div><b>Item(s)</b></div>
    <mat-list>
      <mat-list-item *ngFor="let i of l.orderItems">
        <img matListAvatar style="border-radius: 0px;" [src]="i.listing.thumbnailImg">
        <h3 matLine> {{i.listing.name + ' (' + i.qty + ') - $' + (i.qty * i.purchasedPrice) }} </h3>
        <a matTooltip="View this listing in a new tab" [href]="i.listing | productUrl" target="_blank" mat-icon-button>
          <mat-icon>open_in_new</mat-icon>
        </a>
      </mat-list-item>
    </mat-list>
  </mat-card>
  </ng-container>
</ng-container>

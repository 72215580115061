import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IWedzeeOrderItem, IWedzeeOrderWithListings, TWedzeeTrackingStatus } from 'wz-types';

import { IWedzeeOrder } from '../../../../../../../types/orders';
import { Globals, SellerAccount } from '../../classes';
import { AlertService } from '../../services/alert/alert.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-order-item',
  templateUrl: './order-item.component.html',
  styleUrls: ['./order-item.component.scss']
})
export class OrderItemComponent implements OnInit {
  @Input() order: IWedzeeOrderWithListings;
  @Output() hidden: EventEmitter<void> = new EventEmitter();
  orderTracking: {
    status: TWedzeeTrackingStatus,
    formattedStatus: string,
    tooltipVerbiage: string,
    icon: string
  };
  isOnSellerPage = false;
  hasCancelledItems?: boolean;

  isAllCustom = false;

  hasAllListings = true;

  constructor(
    private http: HttpClient,
    private alertSrv: AlertService
  ) { }

  ngOnInit() {
    if (this.order && this.order.listings) {
      console.log(this.order);
      const orderListingIds = this.order.listings.filter(l => !!l && l.id).map(l => l.id);
      this.hasAllListings = !!this.order.listingIds ? this.order.listingIds.every(lis => orderListingIds.includes(lis)) : false;
      // If this is on the seller page, filter the items down to the seller's items.
      this.isOnSellerPage = true;
      this.hasCancelledItems = !this.order.items.every(i => !i.isCancelled);
      this.order.items = this.order.items
        .filter(i => {
          const listing = this.order.listings.find(l => l.id === i.listingId);
          return !!listing && Globals.user.SellerAccount.id === listing.sellerId;
        })
        .map((i: IWedzeeOrderItem) => {
          i.uiStatus = SellerAccount.wedzeeShippingStatusToUiStatus(i.trackingStatus);
          return i;
        });

      this.isAllCustom = this.order.items.every(i => i.sellerAcknowledgedCustomShipping);
    }
    this.setTrackingStatus();
  }

  setTrackingStatus(): void {
    const allItemsHaveStatus = this.order.items.every(i => !!i.trackingStatus);
    const hasStatus = (status: TWedzeeTrackingStatus) => this.order.items.map(i => i.trackingStatus).indexOf(status) > -1; // trackingObjects.map(t => t.tracking_status.status).indexOf(status) > -1;
    if (!allItemsHaveStatus || hasStatus('processing')) {
      this.orderTracking = {
        status: 'processing',
        formattedStatus: 'Processing',
        tooltipVerbiage: 'Payment has been received and the order is getting ready to ship.',
        icon: 'cached'
      };
    } else if (hasStatus('in_transit')) {
      this.orderTracking = {
        status: 'in_transit',
        formattedStatus: 'In Transit',
        tooltipVerbiage: 'One or more items have shipped.',
        icon: 'local_shipping'
      };
    } else if (hasStatus('delivered')) {
      this.orderTracking = {
        status: 'delivered',
        formattedStatus: 'Delivered',
        tooltipVerbiage: 'All items on this order have been delivered.',
        icon: 'done'
      };
    } else {
      this.orderTracking = {
        status: 'failed',
        formattedStatus: 'Error',
        tooltipVerbiage: 'Something went wrong with one or more of your items in this order. Our support team will be contacting you soon.',
        icon: 'error'
      };
    }
  }

  getThumbnailImg(listingId: string) {
    return this.order.listings.find(l => l.id === listingId).thumbnailImg;
  }

  getListingName(listingId: string) {
    return this.order.listings.find(l => l.id === listingId).name;
  }

  async hideOrder(orderId: string) {
    await this.http.get(`${Globals.environment.apiUrl}orders/seller-hide/${orderId}`).toPromise();
    this.hidden.emit();
    this.alertSrv.successToast('Order hidden');
  }

  hasLabelsForItems(order: IWedzeeOrder) {
    const sellerListingIds = this.order.listings.filter(l => l.sellerId === Globals.user.id).map(l => l.id);
    const sellerOrderItems = order.items.filter(item => sellerListingIds.includes(item.listingId));
    return sellerOrderItems.every(item => (item.shippoLabelIds || []).length > 0);
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QuerySnapshot } from '@angular/fire/firestore';
import { BehaviorSubject, forkJoin, from, Observable, of, Subject } from 'rxjs';
import { debounceTime, map, mergeMap } from 'rxjs/operators';
import {
  IAddress,
  IListing,
  IListingComment,
  IListingForm,
  IListingLike,
  IListingVariation,
  IProductDetailPageData,
  IProductListPageQueryParams,
  IProductListPageQueryResponse
} from 'wz-types';

import { Globals } from '../classes';
import { wzCatchObservableError } from '../services/logging/logging.service';
import { AppStore, FirestoreRefs } from './../classes';

export interface IListingQueryResult {
    numOfPages$: BehaviorSubject<number>;
    numOfResults$: BehaviorSubject<number>;
    listings$: BehaviorSubject<IListing[]>;
    moreLoaded$: Subject<void>;
    isLoading$: BehaviorSubject<boolean>;
}

export type TListingAction = 'getProductDetailData' | 'getListingsByIds' | 'getMoreOfThisColor' | 'getListingsBySeller' |
    'query' | 'getUserLikedItems' | 'getUserCommentedItems' | 'getSimilarAndColor' | 'addComment' | 'saveListing' |
    'deleteListing' | 'getShippingCost' | 'getShipFromAddress' | 'calculateVariationsGrid' | 'getAllMasterVariations'|'getMasterVariationById'
    |'update-listing-for-variations'|'getListing' | 'track-order';

@Injectable()
export class ListingsStore extends AppStore<IListing, TListingAction> {
    fileName = 'listings.store.ts';
    cacheHeaders: HttpHeaders;
    private currentProductListPageQueryParams: IProductListPageQueryParams;

    constructor(
        private http: HttpClient
    ) {
        super({
            objectName: 'listing',
            objectIdKey: 'id',
            objectGetFn: (id: string) => this.http.get(`${Globals.environment.apiUrl}listings/${id}`) as any
        });
        const apiUrl = Globals.environment.apiUrl;
        this.cacheHeaders = new HttpHeaders({ cacheInMemory: '1' });

        this.registerAction('getProductDetailData', {
            type: 'get',
            dispatch: (listingId: string) => this.http.get(`${apiUrl}page-data/product-detail/${listingId}`) as any,
            reduce: (d: IProductDetailPageData) => d.listing,
            map: (d: IProductDetailPageData, lookup, exp) => {
                d.listing = lookup[d.listing.id];
                return d;
            }
        });

        this.registerAction('getListingsByIds', {
            type: 'get',
            dispatch: (ids: string[]) => forkJoin(ids.map(id => this.get(id))) as any,
            reduce: (listings: IListing[]) => listings,
        });

        this.registerAction('getMoreOfThisColor', {
            type: 'get',
            dispatch: (listingId, colorId: string) => this.http.get(`${Globals.environment.apiUrl}listings/color/${colorId}`, { headers: this.cacheHeaders }).pipe(
                map((r: IListing[]) => <any>{ listings: r, listingId })
            ) as any,
            reduce: (r: { listings: IListing[], listingId: string }) => r.listings,
            map: ((r: { listings: IListing[], listingId: string }) => r.listings)
        });

        this.registerAction('getListingsBySeller', {
            type: 'get',
            dispatch: (sellerId: string, archiveListings?: boolean) => this.getListingsBySeller(sellerId, archiveListings).pipe(
                map((l: IListing[]) => <any>{ listings: l, sellerId })
            ),
            reduce: r => r.listings,
            map: (r: { listings: IListing[]; sellerId: string; }, state) => {
                return Object.values(state).filter((l: IListing) => l.sellerId === r.sellerId).sort(this.sortByCreatedTimestamp);
            }
        });

        this.registerAction('query', {
            type: 'get',
            dispatch: (encodedQuery: string) => this.http.get(`${apiUrl}listings/query/${encodedQuery}`),
            reduce: (r: IProductListPageQueryResponse) => r.listings,
            map: (r: IProductListPageQueryResponse) => r,
            discardResponse: true
        });

        this.registerAction('getUserLikedItems', {
            type: 'get',
            dispatch: () => from(FirestoreRefs.listingLikes.ref.where('userId', '==', Globals.user.id).get()).pipe(
                mergeMap((querySnap: QuerySnapshot<IListingLike>) => {
                    const likeDocs = querySnap.docs.map(d => d.data());
                    const ids = likeDocs.map(l => l.listingId);
                    let getObs = this.http.get(`${apiUrl}listings/multiple/${encodeURI(JSON.stringify(ids))}`);
                    if (likeDocs.length === 0) {
                      getObs = of([]);
                    }
                    return getObs;
                }),
            ),
            map: r => r
        });

        this.registerAction('getUserCommentedItems', {
            type: 'get',
            dispatch: () => from(FirestoreRefs.listingComments.ref.where('userId', '==', Globals.user.id).get()).pipe(
                mergeMap((querySnap: QuerySnapshot<IListingComment>) => {
                    const comments = querySnap.docs.map(d => d.data());
                    const ids = comments.map(c => c.listingId);
                    let getObs = this.http.get(`${apiUrl}listings/multiple/${encodeURI(JSON.stringify(ids))}`);
                    if (comments.length === 0) {
                      getObs = of([]);
                    }
                    return getObs;
                })
            ),
            reduce: (r: any) => r.listings,
            map: r => r
        });

        this.registerAction('getSimilarAndColor', {
            type: 'get',
            dispatch: (listingId: string) => this.http.get(`${apiUrl}listings/color-similar/${listingId}`),
            reduce: (r: { color: IListing[]; similar: IListing[]; }) => [...r.color, ...r.similar],
            map: (r: IProductListPageQueryResponse) => r,
            discardResponse: true
        });

        this.registerAction('getShippingCost', {
            type: 'get',
            dispatch: (listingId: string, itemQty: string,
                PostCode: string, CountryCode: string) =>
                this.http.get(`${apiUrl}listings/get-shipping-cost/${listingId}/${itemQty}/${PostCode}/${CountryCode}`),
            map: (r: number) => r,
            discardResponse: true
        });

        this.registerAction('getShipFromAddress', {
            type: 'get',
            dispatch: (addressId: string) =>
                this.http.get(`${apiUrl}listings/get-shipping-address/${addressId}`),
            map: (a: IAddress) => a,
            discardResponse: true
        });

        this.registerAction('calculateVariationsGrid', {
            type: 'change',
            dispatch: (variation: IListingVariation) =>
                // this.http.get(`${apiUrl}listings/calculate-variations-grid/${variation}`),
                this.http.post(`${apiUrl}listings/calculate-variations-grid`, {
                    variation
                }),
            reduce: (l: IListingVariation) => l,
            map: (r: IListingVariation) => r,
            discardResponse: true
        });



        this.registerAction('getAllMasterVariations', {
            type: 'get',
            dispatch: () =>
                this.http.get(`${apiUrl}listings/get-all-master-variations/1`),
            map: (r: IListingVariation[]) => r,
            discardResponse: true
        });

        this.registerAction('getMasterVariationById', {
            type: 'get',
            dispatch: (variation: IListingVariation) =>
                this.http.get(`${apiUrl}listings/get-master-variation/${variation}`),
            map: (r: IListingVariation[]) => r,
            discardResponse: true,
        });

        this.registerAction('saveListing', {
            type: 'change',
            dispatch: (
                listingForm: IListingForm,
                existingListingId: string,
                sellerId: string,
                isUnfinished?: boolean
            ) =>
                this.http.post(`${Globals.environment.apiUrl}listings/add-update`, {
                    listingForm,
                    existingListingId,
                    sellerId,
                    isUnfinished
            }),
            reduce: (l: IListing) => {
                return l;
            },
            map: (l: IListing) => {
                return l;
            }
        });

        this.registerAction('track-order', {
            type: 'change',
            dispatch: (
                orderId: string,
                subTotal: number,
                affiliatly_aff_uid: string,
                affiliatly_id_user: string,
                affiliatly_id_token: string
            ) =>
                this.http.post(`${Globals.environment.apiUrl}listings/track-order`, {
                    orderId,
                    subTotal,
                    affiliatly_aff_uid,
                    affiliatly_id_user,
                    affiliatly_id_token
            })
        });

        this.registerAction('update-listing-for-variations', {
            type: 'change',
            dispatch: (
                variation: any,
                listingId: any
            ) =>
                this.http.post(`${Globals.environment.apiUrl}listings/update-listing-for-variations`, {
                     variation,
                     listingId
            }),
            reduce: (l: IListing) => l,
            map: (l: IListing) => l,
        });

        this.registerAction('deleteListing', {
            type: 'delete',
            dispatch: (listingId: string) => this.http.delete(`${Globals.environment.apiUrl}listings/delete/${listingId}`).pipe(
                map(() => listingId)
            ),
            reduce: (l: IListing) => undefined,
            map: () => undefined,
        });

        this.registerAction('getListing', {
            type: 'get',
            dispatch: (listingId: string) =>
            this.http.get(`${Globals.environment.apiUrl}listings/${listingId}`),
            map: (r: IListingVariation[]) => r,
            discardResponse: true
        });


    }

    public productListPageQuery(params$: Observable<IProductListPageQueryParams>): IListingQueryResult  {
        let params: IProductListPageQueryParams;
        const result: IListingQueryResult = {
          numOfPages$: new BehaviorSubject(0),
          numOfResults$: new BehaviorSubject(0),
          listings$: new BehaviorSubject([]),
          moreLoaded$: new Subject(),
          isLoading$: new BehaviorSubject(true)
        };

        params$.pipe(
          debounceTime(250),
          mergeMap((paramsRes: IProductListPageQueryParams) => {
            result.isLoading$.next(true);
            params = paramsRes;
            this.currentProductListPageQueryParams = paramsRes;
            const encodedParams = encodeURI(JSON.stringify(params).split('/').join('*FSLASH*'));
            return this.dispatch('query', encodedParams);
          }),
          map((r: IProductListPageQueryResponse) => {
            result.listings$.next(r.listings);
            result.numOfPages$.next(r.numOfPages);
            result.numOfResults$.next(r.numOfResults);
            result.moreLoaded$.next();
            result.isLoading$.next(false);
          }),
          wzCatchObservableError(this.fileName, 'productListPageQuery()')
        ).subscribe();
        return <any>result;
    }

    public clearProductListPageQuery(): void {
        this.currentProductListPageQueryParams = undefined;
    }

    public getProductListPageQuery(): IProductListPageQueryParams {
        return this.currentProductListPageQueryParams;
    }

    private getListingsBySeller(sellerId: string, archiveListings?: boolean): Observable<IListing[]> { // why does .orderBy('createdTimestamp') not work?
        return from((archiveListings ? FirestoreRefs.archiveListings : FirestoreRefs.listings).ref.where('sellerId', '==', sellerId).get()).pipe(
            map((d: any) => d.docs.map((document: any) => document.data())),
            wzCatchObservableError(this.fileName, 'getListingsBySeller()')
        );
    }

    private sortByCreatedTimestamp(a, b) {
        return b.createdTimestamp - a.createdTimestamp;
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { forkJoin, from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IListingComment } from 'wz-types/listings';

import { AppStore, FirestoreRefs, Globals } from '../classes';
import { AlertService } from '../services/alert/alert.service';
import { wzCatchObservableError } from '../services/logging/logging.service';

type TListingCommentAction = 'addComment' | 'getComments' | 'updateComment' | 'deleteComment';

@Injectable()
export class CommentsByListingStore extends AppStore<{ listingId: string; comments: IListingComment[]}, TListingCommentAction> {
    private fileName = 'listing-comment.store.ts';

    constructor(
        private http: HttpClient,
        private alertSrv: AlertService,
        private firestore: AngularFirestore
    ) {
        super({
            objectName: 'commentsByListing',
            objectIdKey: 'listingId',
            // tslint:disable-next-line: deprecation
            objectGetFn: (listingId: string) => forkJoin(this.getCommentsByListing(listingId), of(listingId)).pipe(
                map((r: [IListingComment[], string]) => <any>{ listingId: r[1], comments: r[0] })
            )
        });

        this.registerAction('addComment', {
            type: 'change',
            dispatch: (listingId: string, commentText: string) => this.addComment(listingId, commentText),
            reduce: (comment: IListingComment, state) => {
                return <any>{ listingId: comment.listingId, comments: [...state[comment.listingId].comments, comment] };
            },
            map: (comment: IListingComment, state) => state[comment.listingId].comments
        });

        this.registerAction('updateComment', {
            type: 'change',
            dispatch: (c: IListingComment) => {
                const newComment = { ...c, editedTimestamp: new Date().getTime() };
                return from(FirestoreRefs.listingComments.doc(c.id).update(newComment)).pipe(map(() => newComment));
            },
            reduce: (comment: IListingComment, state) => <any>{
                listingId: comment.listingId,
                comments: [comment, ...state[comment.listingId].comments].filter((v, i, a) => a.map(c => c.id).indexOf(v.id) === i)
            },
            map: (comment: IListingComment, state) => state[comment.listingId].comments
        });

        this.registerAction('deleteComment', {
            type: 'change',
            dispatch: (c: IListingComment) => from(FirestoreRefs.listingComments.doc(c.id).delete()).pipe(map(() => c)),
            reduce: (c: IListingComment, state) => <any>{ listingId: c.listingId, comments: state[c.listingId].comments.filter(com => com.id !== c.id) },
            map: (comment: IListingComment, state) => state[comment.listingId].comments
        });

        this.registerAction('getComments', {
            type: 'get',
            // tslint:disable-next-line: deprecation
            dispatch: (listingId: string) => forkJoin(this.getCommentsByListing(listingId), of(listingId)).pipe(
                map((r: [IListingComment[], string]) => <any>{ listingId: r[1], comments: r[0] })
            ),
            reduce: (data: { listingId: string; comments: IListingComment[] }) => data,
            map: (data: { listingId: string; comments: IListingComment[] }) => data.comments
        });
    }


    private getCommentsByListing(listingId: string): Observable<IListingComment[]> {
        return from(FirestoreRefs.listingComments.ref
            .where('listingId', '==', listingId)
            .orderBy('createdTimestamp', 'asc')
            .get()).pipe(
            map((querySnap: any) => querySnap.docs.map(d => d.data())),
            wzCatchObservableError(this.fileName, 'getCommentsByListing()')
        );
    }

    private addComment(listingId: string, commentText): Observable<IListingComment> {
        const comment: IListingComment = {
            createdTimestamp: new Date().getTime(),
            userName: Globals.user.username,
            userId: Globals.user.id,
            userImgUrl: Globals.user.photoURL || 'assets/img-default/user.jpg',
            id: this.firestore.createId(),
            commentText,
            listingId
        };
        return from(FirestoreRefs.listingComments.doc(comment.id).set(comment)).pipe(
            map(() => {
                this.alertSrv.successToast('Your comment was posted');
                return comment;
            }),
            wzCatchObservableError(this.fileName, 'addComment()')
        );
    }

}

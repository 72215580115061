import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of as observableOf, Subject } from 'rxjs';
import { delay, filter, map, mergeMap, take, takeUntil } from 'rxjs/operators';
import { ICroppedImage } from 'wz-types';

import { ImgCropperDialogComponent } from '../../dialogs/img-cropper-dialog/img-cropper-dialog.component';
import { ImgPreviewComponent } from '../../dialogs/img-preview/img-preview.component';

export interface IAspectRatio { width: number; height: number; }

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-add-image',
  templateUrl: './add-image.component.html',
  styleUrls: ['./add-image.component.scss']
})
export class AddImageComponent implements OnInit, OnDestroy, OnChanges {
  isCypress = !!(window as any).Cypress;
  @Output() imgAdded: EventEmitter<string> = new EventEmitter();
  @Output() imgChangeEvents: EventEmitter<string> = new EventEmitter();
  @Output() isUploading: EventEmitter<boolean> = new EventEmitter();
  @Input() widthPx = 200;
  @Input() aspectRatio: IAspectRatio;
  @Input() index: number;
  @Input() listLength = 0;
  @Input() disabled = false;
  @Input() imgType: string;
  @Input() originalImgUrl: string;
  @Input() placeholderSrc?: string;
  @Input() resetAfterEmit?: boolean;
  @Input() quality = 50; // Optional parameter for the quality level of the photo output (1-100)
  imgBackground = 'assets/img-default/camera.png';
  uploadedImgUrl: string;
  imgWidth: string;
  imgHeight: string;
  hasIndex: boolean;
  uploadProgress: number;
  fileName: string;
  inputIds: string[] = [];

  background = {
    top: '',
    left: '',
    width: ''
  };

  backgroundTop: string;
  backgroundWidth: string;

  destroy$: Subject<void> = new Subject();

  constructor(
    private dialog: MatDialog,
    private firebaseStorage: AngularFireStorage,
    private firestore: AngularFirestore,
  ) { }

  ngOnInit() {
    this.hasIndex = !!this.index || this.index === 0;
    this.imgBackground = this.placeholderSrc ? this.placeholderSrc : this.imgBackground;
    this.updateInputId();
    this.uploadedImgUrl = this.originalImgUrl ? this.originalImgUrl : undefined;
    const r = this.aspectRatio || undefined;
    const smaller = (isHeight?: boolean) => Math.round(this.widthPx / 1.5) + (isHeight ? 37 : 0);
    const bigger = (isHeight?: boolean) => Math.round(this.widthPx * 1.5) + (isHeight ? 37 : 0);
    if (!!r && r.width < r.height) {
      this.imgWidth = smaller() + 'px';
      this.imgHeight = bigger(true) + 'px';
      this.background = {
        top: '23%',
        left: '10%',
        width: '80%',
      };
    } else if (!!r && r.width > r.height) {
      this.imgWidth = bigger() + 'px';
      this.imgHeight = smaller(true) + 'px';
      this.background = {
        top: '12%',
        left: '25%',
        width: '50%',
      };
    } else {
      this.imgWidth = smaller() + 'px';
      this.imgHeight = smaller(true) + 'px';
      this.background = {
        top: '12%',
        left: '10%',
        width: '80%',
      };
    }
  }

  ngOnChanges() {
    this.uploadedImgUrl = this.originalImgUrl ? this.originalImgUrl : undefined;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateInputId() {
    if (!this.fileName || this.resetAfterEmit) {
      const uid = this.firestore.createId() + new Date().getTime();
      this.fileName = `${this.imgType}/${this.imgType}_${uid}.jpeg`;
      this.inputIds.push(`addImageInput_${uid}`);
    }
  }

  fileChange(fileChangeEvent: any, isBase64?: boolean) {
    this.updateInputId();
    const dialogRef = this.dialog.open(ImgCropperDialogComponent, {
      hasBackdrop: true,
      width: '500px',
      maxWidth: '90%',
      data: { fileChangeEvent, aspectRatio: this.aspectRatio, isBase64, quality: this.quality }
    });

    dialogRef.afterClosed().pipe(
      mergeMap((croppedImage: ICroppedImage) => {
        let uploadObs = () => new Observable((subscriber) => {
          this.isUploading.emit(true);
          let uploadTask: AngularFireUploadTask;
          if (croppedImage.file) {
            uploadTask = this.firebaseStorage.upload(this.fileName, croppedImage.file, { contentType: 'image/jpeg' });
          } else if (croppedImage.base64) {
            uploadTask = this.firebaseStorage.ref(this.fileName).putString(croppedImage.base64.split(',')[1], 'base64', { contentType: 'image/jpeg' });
          }
          uploadTask.percentageChanges().pipe(
            takeUntil(this.destroy$)
          ).subscribe(
            (res) => {
              this.uploadProgress = res;
            },
            (err: any) => {
              subscriber.error(err);
            },
            () => {
              subscriber.next();
              subscriber.complete();
              delete croppedImage.base64;
            });
        }).pipe(
            delay(300),
            mergeMap(() => this.firebaseStorage.ref(this.fileName).getDownloadURL())
          );
        if (!croppedImage) {
          uploadObs = () => observableOf(undefined);
        }
        return uploadObs();
      }),
      map((imgUrl: string) => {
        if (!!imgUrl) {
          this.uploadedImgUrl = imgUrl;
          this.imgAdded.emit(imgUrl);
          if (this.resetAfterEmit) {
            this.uploadedImgUrl = undefined;
          }
          this.updateInputId();
        }
        this.isUploading.emit(false);
      }),
      take(1)
    ).subscribe();
  }

  imageClick(event: any) {
    if (!!this.uploadedImgUrl) {
      ImgPreviewComponent.open(this.uploadedImgUrl, this.dialog, this.imgType).pipe(
        take(1),
        filter(r => !!r),
        map((r: any) => this.imgChangeEvents.emit(r))
      ).subscribe();
    }
  }

  openFileInput() {
    if (this.inputIds.length > 0) {
      const input = document.getElementById(this.inputIds[this.inputIds.length - 1]);
      input.click();
    }
  }
}

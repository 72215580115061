<mat-dialog-content>
  <div style="min-height: 600px!important" [ngClass]="isLoading ? 'loading-opacity' : ''">
    <div>
      <h1 mat-dialog-title style="font-weight: bold; float: left">
        {{ isRegistration ? 'Register' : isSellerGuard ? 'Sign in to seller account' : 'Sign in' }}
      </h1>
      <button mat-icon-button mat-dialog-close style="float: right;">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <span *ngIf="isLoading" style="position: static; width: 100%!important; text-align: center; top: 200px;">
      <div style="height: 200px;"></div>
      <mat-spinner style="margin: auto;" color="accent"></mat-spinner>
    </span>

    <div style="padding: 40px 0 20px;">
      <img [src]="logoImgSrc" style="height:35px;">
    </div>
    
    <form *ngIf="!!authForm" [formGroup]="authForm" (ngSubmit)="submitAuthForm()">
      <ng-container *ngIf="!isLoading">
        <mat-form-field class="full-width">
          <mat-label>Email address</mat-label>
          <input cdkFocusInitial formControlName="email" matInput placeholder="Enter email" type="text">
          <mat-error *ngIf="!authForm.get('email').valid">Please enter a valid email adress</mat-error>
        </mat-form-field>

        <wz-username-input (username)="authForm.get('username').setValue($event)" *ngIf="isRegistration"></wz-username-input>

        <mat-form-field class="full-width">
          <mat-label>Password</mat-label>
          <input formControlName="password" matInput placeholder="Enter password" type="password">
          <mat-error *ngIf="!authForm.get('password').valid">{{passwordRequirements}}</mat-error>
        </mat-form-field>

        <ng-container *ngIf="isRegistration && authForm.get('email').valid && authForm.get('username').valid && authForm.get('password').valid as needRecaptcha">
          <re-captcha *ngIf="env.production"
            style="transform:scale(0.5); transform-origin:0 0; margin-bottom: 15px;"
            formControlName="registerRecaptcha"
          ></re-captcha>
          <div style="height: 15px;"></div><!--This is a spacer to provide padding between elements-->
        </ng-container>

        <div *ngIf="errorMsgs.email as message" style="margin: 15px auto 15px auto; padding-left: 12px; padding-right: 12px;">
          <mat-error>
            {{message}}
          </mat-error>
        </div>

      </ng-container>


      <button mat-raised-button
        color="accent"
        [disabled]="!authForm.valid"
        class="full-width wdz-btn"
        type="submit"
        [ngStyle]="{ 'margin-top': !authForm.get('password').valid && authForm.get('password').touched ? '32px' : '' }">
        {{ isRegistration ? 'Register' : 'Sign in' }}
      </button>
    </form>

    <div *ngIf="!isRegistration" class="text-center" style="font-size: smaller; margin: 15px; cursor: pointer;">
        <a (click)="close(false)" [routerLink]="['/forgot-password']">Forgot your password?</a>
    </div>

    <div *ngIf="!isRegistration" class="text-center" style="font-size: smaller; margin: 15px; cursor: pointer;">
        <a (click)="toggleAuthType()">Don't have an account? Register</a>
    </div>

    <div *ngIf="isRegistration" class="text-center" style="font-size: smaller; margin: 15px; cursor: pointer;">
        <a (click)="toggleAuthType()">Already have an account? Sign in</a>
    </div>

    <div style="width: 100%; text-align: center; margin-top: 15px; margin-bottom: 15px;">OR</div>

    <mat-error class="oauth-provider-error" *ngIf="errorMsgs.google as message">
      {{message}}
    </mat-error>

    <img style="width: 100%;" (click)="google()" src="assets/img/oauth-providers/google-button.png">

    <mat-error class="oauth-provider-error" *ngIf="errorMsgs.facebook as message">
      {{message}}
    </mat-error>

    <img style="width: 100%; margin-top: 25px; margin-bottom: 25px;" (click)="facebook()" src="assets/img/oauth-providers/facebook-button.png">

    <span color="light" style="font-size: smaller;">
      By clicking Register, Sign in with Google, or Continue with Facebook, you agree to Wedzee's <a>Terms of Service</a> and <a>Privacy Policy</a>. Wedzee may send you communications; you may change your preferences in your account settings. Wedzee will never post without your permission.
    </span>

  </div>
</mat-dialog-content>



import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';

import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent } from './../../dialogs/alert-dialog/alert-dialog.component';


@Injectable({
  providedIn: 'root'
})
export class AlertService {


  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {

  }

  successToast(message: string): void {
    this.snackBar.open(message, 'Ok', { duration: 2000 });
  }

  confirm(title: string, message: string, confirmBtnText?: string): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      hasBackdrop: true,
      width: '90%',
      maxWidth: '350px',
      data: { title, message, confirmBtnText }
    });

    return dialogRef.afterClosed();
  }

  alert(title: string, message: string): void {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      hasBackdrop: true,
      width: '90%',
      maxWidth: '350px',
      data: { title, message }
    });
  }
}

import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_LABEL_GLOBAL_OPTIONS } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, TOOLTIP_PANEL_CLASS } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { RECAPTCHA_SETTINGS, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { ImageCropperModule } from 'ngx-image-cropper';

import { FirestoreRefs, Globals } from './classes';
import {
  AddImageComponent,
  BottomSheetMenuComponent,
  CategoryComponent,
  ConnectStripeComponent,
  EnterAddressComponent,
  FooterComponent,
  FreeShippingComponent,
  InfoBulletsComponent,
  NavbarMenuComponent,
  OrderItemComponent,
  OrderItemWithTrackingComponent,
  PaypalButtonComponent,
  PriceQtyShippingComponent,
  ProductComponent,
  ProductThumbComponent,
  SearchComponent,
  ShoppingCartButtonComponent,
  ShoppingCartItemComponent,
  StripePaymentComponent,
  TrustIconComponent,
  UsernameInputComponent,
  WeddingColorComponent,
  WzNavbarComponent,
  WzTooltipComponent
} from './components';
import { AboutSectionFormItemComponent } from './components/about-section-form-item/about-section-form-item.component';
import { BuyOnWedzeeComponent } from './components/buy-on-wedzee/buy-on-wedzee.component';
import { HowAffiliateWorksComponent } from './components/how-affiliate-works/how-affiliate-works.component';
import { SellOnWedzeeFormItemComponent } from './components/sell-on-wedzee-form-item/sell-on-wedzee-form-item.component';
import { SellOnWedzeePageComponent } from './components/sell-on-wedzee-page/sell-on-wedzee-page.component';
import {
  AddAddressDialogComponent,
  AlertDialogComponent,
  ConfirmDialogComponent,
  ImgCropperDialogComponent,
  ImgPreviewComponent,
  LoginDialogComponent,
  SelectCategoryComponent,
  ShareDialogComponent,
  TwoOptionsDialogComponent,
  ShippingCostDialogComponent
} from './dialogs';
import {
  CopyToClipboardDirective,
  DebounceHoverDirective,
  DebounceKeyupDirective,
  DistributedWidthDirective,
  DollarInputDirective,
  MustSignInDirective,
  RouterLinkAbsoluteDirective,
  RouterLinkNewTabDirective,
  ScrollableDirective,
  StripePaymentDirective,
  UploadImgDirective,
  NoAutoCompleteDirective
} from './directives';
import { WzInterceptor } from './http-interceptor.interceptor';
import { AuthHandlerPageComponent } from './pages/auth-handler/auth-handler.component';
import { CreateEditItemPageComponent } from './pages/create-edit-item-page/create-edit-item-page.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import {
  ArrayOfNumbersPipe,
  CategoryUrlPipe,
  MaxCharactersPipe,
  OrderIdPipe,
  ProductUrlPipe,
  SafeUrlPipe,
  SearchFilterPipe,
  UiAdressPipe
} from './pipes';
import { AlertService, AuthService, ImageCompressionService, LoggingService, SeoService } from './services';
import { PagesPoliciesService } from './services/pages-policies-service/pages-policies.service';
import {
  CommentsByListingStore,
  DisputesStore,
  LikesByListingStore,
  ListingsStore,
  OrdersStore,
  SellersStore
} from './stores';
import { WzAngularMaterialModule } from './wz-angular-material/wz-angular-material.module';
import { MoneyBackBannerComponent } from '../../../consumer/src/app/components/money-back-banner/money-back-banner.component';
import { FeaturedListingsComponent } from './components/featured-listings/featured-listings.component';
import { AllCategoriesComponent } from './components/all-categories/all-categories.component';
import { AllColorsComponent } from './components/all-colors/all-colors.component';

const ENV = Globals.environment;

const componentsDirectivesPipes = [
  // Components here,
  SelectCategoryComponent,
  ConfirmDialogComponent,
  AlertDialogComponent,
  LoginDialogComponent,
  TwoOptionsDialogComponent,
  AddAddressDialogComponent,
  BottomSheetMenuComponent,
  FooterComponent,
  SearchComponent,
  WzNavbarComponent,
  ForgotPasswordComponent,
  AuthHandlerPageComponent,
  AddImageComponent,
  ImgCropperDialogComponent,
  TrustIconComponent,
  CreateEditItemPageComponent,
  WeddingColorComponent,
  CategoryComponent,
  AllCategoriesComponent,
  AllColorsComponent,
  ImgPreviewComponent,
  ProductComponent,
  ProductThumbComponent,
  FeaturedListingsComponent,
  ShoppingCartButtonComponent,
  MoneyBackBannerComponent,
  FreeShippingComponent,
  ShoppingCartItemComponent,
  PriceQtyShippingComponent,
  OrderItemComponent,
  PaypalButtonComponent,
  EnterAddressComponent,
  ConnectStripeComponent,
  OrderItemWithTrackingComponent,
  BuyOnWedzeeComponent,
  HowAffiliateWorksComponent,
  SellOnWedzeePageComponent,
  UsernameInputComponent,
  InfoBulletsComponent,
  WzTooltipComponent,
  StripePaymentComponent,
  AboutSectionFormItemComponent,
  SellOnWedzeeFormItemComponent,
  ShippingCostDialogComponent,

  // Directives here
  ScrollableDirective,
  CopyToClipboardDirective,
  MustSignInDirective,
  StripePaymentDirective,
  DollarInputDirective,
  DebounceHoverDirective,
  RouterLinkAbsoluteDirective,
  RouterLinkNewTabDirective,
  DebounceKeyupDirective,
  DistributedWidthDirective,
  UploadImgDirective,
  NoAutoCompleteDirective,

  NavbarMenuComponent,
  ShareDialogComponent,

  // Pipes here
  SearchFilterPipe,
  SafeUrlPipe,
  UiAdressPipe,
  MaxCharactersPipe,
  OrderIdPipe,
  ArrayOfNumbersPipe,
  ProductUrlPipe,
  CategoryUrlPipe
];

@NgModule({
  entryComponents: [
    SelectCategoryComponent,
    AlertDialogComponent,
    ConfirmDialogComponent,
    LoginDialogComponent,
    BottomSheetMenuComponent,
    ImgCropperDialogComponent,
    ImgPreviewComponent,
    TwoOptionsDialogComponent,
    ShareDialogComponent,
    AddAddressDialogComponent,
    ShippingCostDialogComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    FlexLayoutModule,
    WzAngularMaterialModule,
    AngularFireModule.initializeApp(ENV.firebase.config),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    HttpClientModule,
    ReactiveFormsModule,
    RecaptchaModule.forRoot(),
    RecaptchaFormsModule,
    MatTabsModule,
    ImageCropperModule,
    AgmCoreModule.forRoot({
      apiKey: ENV.googlePlaces.apiKey,
      libraries: ['places']
    })
  ],
  declarations: [
    ...componentsDirectivesPipes,
    FeaturedListingsComponent,
    AllCategoriesComponent,
    AllColorsComponent,
  ],
  providers: [
    AuthService,
    LoggingService,
    AlertService,
    ListingsStore,
    OrdersStore,
    DisputesStore,
    CommentsByListingStore,
    SellersStore,
    LikesByListingStore,
    SeoService,
    ImageCompressionService,
    PagesPoliciesService,
    FirestoreRefs,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WzInterceptor,
      multi: true
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: ENV.recaptchaSiteKey } as RecaptchaSettings,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline', floatLabel: true }
    },
    {
      // tslint:disable-next-line: deprecation
      provide: MAT_LABEL_GLOBAL_OPTIONS,
      useValue: { float: 'auto' }
    },
    {
      provide: MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS,
      useValue: { strokeWidth: 5, color: 'accent' }
    },
    {
      provide: TOOLTIP_PANEL_CLASS,
      useValue: 'mat-tooltip'
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: { position: 'below', scrollDelay: 0, hideDelay: 0 }
    }
  ],
  exports: [
    // Modules
    WzAngularMaterialModule,
    AngularFireModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    HttpClientModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    MatTabsModule,
    ImageCropperModule,
    FlexLayoutModule,
    ...componentsDirectivesPipes
  ]
})
export class AdminConsumerSharedModule { }

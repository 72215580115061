import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { ICountry } from 'wz-types/listings';
import { ListingsStore } from '~shared/stores';
import { CacheFactory } from 'cachefactory';
@Component({
  selector: 'lib-shippingcostdialog',
  templateUrl: './shipping-cost-dialog.component.html',
  styleUrls: ['./shipping-cost-dialog.component.scss']
})

export class ShippingCostDialogComponent implements OnInit, OnDestroy {

  destroy$ = new Subject();
  shippingCost: any = '0.00';
  deliveryCostForm: FormGroup;
  submitButtonText: String = 'Update';
  country: ICountry[] = [
    { value: 'US', viewValue: 'United States' }
  ];

  constructor(
    public dialogRef: MatDialogRef<ShippingCostDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private zone: NgZone,
    private listingStore: ListingsStore,
  ) { }


  ngOnInit() {
    this.deliveryCostForm = this.formBuilder.group({
      CountryCode: ['US', [Validators.required]],
      PostCode: [undefined, [Validators.required, Validators.pattern(/^([0-9]{5})$/)]]
    });

    const cacheFactory = new CacheFactory();
    let cache;
    if (!cacheFactory.exists('calcShippingPostalCodeCache')) {
      cache = cacheFactory.createCache('calcShippingPostalCodeCache', {
        // 1 hour
        maxAge: 60 * 60 * 1000,
        deleteOnExpire: 'aggressive',
        storageMode: 'localStorage',
      });
       cache = cacheFactory.get('calcShippingPostalCodeCache');
       const selectedPostCode = cache.get('selectedPostCode');
       if (selectedPostCode !== undefined && selectedPostCode !== null) {
        this.deliveryCostForm.controls['PostCode'].setValue(selectedPostCode.postcode);
       }
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  close(shippingCost: number): void {
    const self = this;
    this.zone.run(() => {
      self.dialogRef.close({ data: this.shippingCost });
    });
  }

  calculate() {
    const cacheFactory = new CacheFactory();
    let cache;
    if (!cacheFactory.exists('calcShippingPostalCodeCache')) {
      cache = cacheFactory.createCache('calcShippingPostalCodeCache', {
        // 1 hour
        maxAge: 60 * 60 * 1000,
        deleteOnExpire: 'aggressive',
        storageMode: 'localStorage',
      });
    }
    cache.put('selectedPostCode', {
      postcode: this.deliveryCostForm.value.PostCode
    });
    this.listingStore.dispatch('getShippingCost', this.data.listingId, this.data.Quantity, this.deliveryCostForm.value.PostCode, this.deliveryCostForm.value.CountryCode).subscribe(
      (result) => {
        this.shippingCost = result;
        localStorage.setItem('buyerPostCode', this.deliveryCostForm.value.PostCode);
        this.dialogRef.close(this.shippingCost);
      },
      (err) => console.log(err)
    );
  }

  async submitdeliveryCostForm() {
    this.calculate();
  }

}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFirestore, DocumentSnapshot } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { BehaviorSubject, forkJoin, from, Observable, of, Subject } from 'rxjs';
import { delay, filter, map, mergeMap, take } from 'rxjs/operators';
import {
  IAddress,
  IListing,
  IPayPalCheckoutParams,
  IShoppingCart,
  IShoppingCartItem,
  IShoppingCartItemWithListing,
  IShoppingCartTotals,
  IStripePaymentToken
} from 'wz-types';

import { AuthService } from '../services/auth/auth.service';
import { wzCatchObservableError } from '../services/logging/logging.service';
import { ListingsStore } from '../stores';
import { FirestoreRefs } from './firestore-refs.class';
import { Globals } from './global.class';


export class ShoppingCart implements IShoppingCart {
  public static cartUpdated$: Subject<void> = new Subject();

  fileName = 'shopping-cart.class.ts';
  id: string;
  items: IShoppingCartItem[] = [];
  updatedTimestamp: number;
  items$: BehaviorSubject<IShoppingCartItemWithListing[]> = new BehaviorSubject([]);
  shipToAddress$: BehaviorSubject<IAddress | string> = new BehaviorSubject('no address selected');
  billToAddress$: BehaviorSubject<IAddress | string> = new BehaviorSubject('no address selected');
  shipToAddressId?: string;
  billToAddressId?: string;
  totals: IShoppingCartTotals;
  isUserAnonymous: boolean;
  cartDoc: IShoppingCart;
  paymentMethod: 'stripe' | 'paypal';

  // isCalculatingShipping: boolean;
  isCalculatingTax: boolean;

  isCalculating$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private http: HttpClient,
    private firestore: AngularFirestore,
    private authSrv: AuthService,
    private listingsStore: ListingsStore,
    private router: Router,
    cartDocument?: IShoppingCart,
    isAnonymous?: boolean
  ) {
    this.cartDoc = cartDocument;
    this.refreshShoppingCart(cartDocument, isAnonymous);
  }


  /**
   * Gets a user friendly address string from an address object
   */
  public static getUiAddress(a: IAddress): string {
    return !!a ? `
      ${a.name ? a.name : ''}<br>
      ${!!a.street_no ? a.street_no : ''} ${a.street1} ${a.street2 ? ', # ' + a.street2 : ''}<br>
      ${a.city}, ${a.state} ${a.zip}
    ` : '';
  }


  /**
   * Updates the class values
   */
  refreshShoppingCart(cartDoc: IShoppingCart, isAnonymous: boolean): void {
    this.cartDoc = cartDoc;
    this.isUserAnonymous = !!isAnonymous;
    if (!!cartDoc) {
      cartDoc.items = cartDoc.items || [];
      for (const addrPropName of ['shipToAddressId', 'billToAddressId']) {
        const obsPropName = addrPropName === 'shipToAddressId' ? 'shipToAddress$' : 'billToAddress$';
        if (!!cartDoc[addrPropName] && cartDoc[addrPropName] !== this[addrPropName]) {
          from(FirestoreRefs.addresses.doc(cartDoc[addrPropName]).get()).pipe(
            map((snap: DocumentSnapshot<IAddress>) => {
              const address = snap.data();
              this[obsPropName].next(address);
            }),
            take(1)
          ).subscribe();
        }
      }
      Object.keys(cartDoc).forEach((key: string) => this[key] = cartDoc[key]);
      this.instantiateItems(cartDoc.items);
    } else {
      this.items$.next([]);
      this.items = [];
      this.id = undefined;
    }
    ShoppingCart.cartUpdated$.next();
    console.log('Latest Shopping cart: ', this);
  }

  public addUpdateItem(item: IShoppingCartItem): void {
    const ensureAuth = () => Globals.user.isLoggedIn() || Globals.user.isLoggedInAnonymously() ?
      of(undefined) :
      this.authSrv.signInAnonymously().pipe(delay(500));
    const indexOfExistingItem = this.items.map(i => i.listingId).indexOf(item.listingId);
    if (indexOfExistingItem > -1) {
      this.items[indexOfExistingItem] = item;
    } else {
      this.items.push(item);
    }
    this.instantiateItems(this.items);
    if (!!this.shipToAddressId && !!this.billToAddressId) {
      this.router.navigateByUrl('review-order');
    } else {
      this.router.navigateByUrl('shopping-cart');
    }

    this.isCalculating$.next(true);
    ensureAuth().pipe(
      mergeMap(() => {
        const cartDoc = { id: this.id || Globals.user.id, ...this.cartDoc };
        return this.http.post(
         `${Globals.environment.apiUrl}shopping-cart/add-update-item`, { cartDoc, item }
        );
      }),
      mergeMap(() => Globals.shoppingCartUpdated$.pipe(take(1))),
      map(() => this.isCalculating$.next(false)),
      wzCatchObservableError(this.fileName, 'addUpdateItem()'),
      take(1)
    ).subscribe();
  }

  instantiateItems(items: IShoppingCartItem[]) {
    // This long complex boolean scheme is important because it tells us whether we're reloading
    // the items in the UI, which can have a major impact on user experience.
    const areItemsSame = !!items && this.items$.value.length === items.length && items.every(i => {
      const localItem = this.items$.value.find(l => l.listingId === i.listingId);
      const isQtySame = !!localItem && localItem.qty === i.qty;
      const isTaxSame = !!localItem && (!i.taxjar && !localItem.taxjar) ||
        (!!localItem && !!i.taxjar && !!localItem.taxjar && i.taxjar.amount_to_collect === localItem.taxjar.amount_to_collect);
      const notesSame = !!localItem && ((!i.customizationNotes && !localItem.customizationNotes) || i.customizationNotes === localItem.customizationNotes);
      return isQtySame && isTaxSame && notesSame;
    });

    if (!areItemsSame && items.length > 0) {
      this.items = items;
      forkJoin(items.map((i: IShoppingCartItem) => forkJoin([
        of(i),
        this.listingsStore.get(i.listingId)
      ]).pipe(
        map((res: [IShoppingCartItem, IListing]) => {
          return { ...res[0], listing: res[1] };
        }),
        take(1)
      ))).pipe(
        map((cartItems: IShoppingCartItemWithListing[]) => this.items$.next(cartItems)),
        take(1)
      ).subscribe();
    } else if (!items || items.length === 0) {
      this.items$.next([]);
      this.items = [];
    }
  }


  /**
   * Removed an item of the provided listing id from the cart
   */
  remove(listingId: string): void {
    this.isCalculating$.next(true);
    const itemLength = this.items.length;
    this.http.get(`${Globals.environment.apiUrl}shopping-cart/remove/${this.id || Globals.user.id}/${listingId}`).pipe(
      filter(() => {
        this.isCalculating$.next(false);
        return itemLength === 1;
      }),
      take(1),
      map(() => {
        this.router.navigateByUrl('/');
        this.resetAddresses();
      }),
      wzCatchObservableError(this.fileName, 'removeFromCart'),
    ).subscribe();
    this.items = this.items.filter(i => i.listingId !== listingId);
    this.instantiateItems(this.items);
  }

  checkout(paymentMethod: 'stripe' | 'paypal', stripeOrPaypalData: IPayPalCheckoutParams | IStripePaymentToken): Observable<string> {
    const loadingMsg = 'Processing your order...';
    Globals.startLoading(loadingMsg);
    return this.http.post(`${Globals.environment.apiUrl}shopping-cart/checkout/`, {
      paymentMethod,
      stripeOrPaypalData,
      cartDoc: this.cartDoc
    }, { headers: new HttpHeaders('customLoader: 1') }).pipe(
      map((r: {success: boolean, orderId: string}) => {
        Globals.stopLoading(loadingMsg);
        delete this.shipToAddressId;
        delete this.billToAddressId;
        return r.orderId;
      }),
      wzCatchObservableError(this.fileName, 'checkout()', true)
    );
  }

  addUpdatePaymentMethod(paymentMethod: 'stripe' | 'paypal'): Observable<void> {
    return this.http.get(`${Globals.environment.apiUrl}/shopping-cart/add-update-payment-method/${this.id}/${paymentMethod}`).pipe(
      mergeMap(() => Globals.shoppingCartInstantiated$.pipe(take(1)))
    ) as any;
  }

  addUpdateAddress(addressType: 'billing' | 'shipping', address: IAddress) {
    this.isCalculating$.next(true);
    const obsPropName = addressType === 'shipping' ? 'shipToAddress$' : 'billToAddress$';
    this[obsPropName].next(address);
    this.http.post(`${Globals.environment.apiUrl}shopping-cart/add-update-address/`, {
      addressType, cartDoc: this.cartDoc, address
    }).pipe(
      map(() => this.isCalculating$.next(false)),
      wzCatchObservableError(this.fileName, 'addUpdateAddress()', true),
      take(1)
    ).subscribe();
  }

  resetAddresses(): Observable<void> {
    this.shipToAddressId = undefined;
    this.billToAddressId = undefined;
    return from(FirestoreRefs.userShoppingCarts.doc(this.id).update({
      billToAddressId: firebase.firestore.FieldValue.delete(),
      shipToAddressId: firebase.firestore.FieldValue.delete()
    }));
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { IAdminOrderDetail } from 'wz-types/orders';
import { Globals } from '~shared/classes';
import { OrdersStore } from '~shared/stores';


@Component({
  selector: 'wz-admin-order-detail',
  templateUrl: './admin-order-detail.component.html',
  styleUrls: ['./admin-order-detail.component.scss']
})
export class AdminOrderDetailComponent implements OnInit {
  @Input() orderId: string;
  o: IAdminOrderDetail;

  url = Globals.environment.siteUrl;

  constructor(
    private ordersStore: OrdersStore
  ) { }

  ngOnInit() {
    this.ordersStore.getAdminOrderDetail(this.orderId).pipe(
      map((order: IAdminOrderDetail) => {
        this.o = order;

        setTimeout(() => {
          const el = document.getElementById(order.id);
          el.scrollIntoView();
        }, 500);
      }),
      take(1)
    ).subscribe();
  }

}

import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { filter, map, take } from 'rxjs/operators';
import { IListing, IShoppingCartItem, IWeddingColor } from 'wz-types';

import { Globals, User } from '../../classes';
import { AlertService } from '../../services/alert/alert.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-shopping-cart-item',
  templateUrl: './shopping-cart-item.component.html',
  styleUrls: ['./shopping-cart-item.component.scss']
})
export class ShoppingCartItemComponent implements OnChanges {
  @Input() item?: IShoppingCartItem;
  @Input() listing?: IListing;
  @Input() isSkeleton?: boolean;
  @Output() isChangingQuantity: EventEmitter<boolean> = new EventEmitter();
  @Input() removeButtonPadding = '';
  @Input() isEditDisable;
  isLoading: boolean;
  isChangingQty: boolean;
  variationString: string;
  notesForm: FormGroup;
  isCalculating$ = Globals.user.ShoppingCart.isCalculating$;

  constructor(
    private alertSrv: AlertService,
    private formBuilder: FormBuilder
  ) {}

  async ngOnChanges() {
    this.isSkeleton = !!this.isSkeleton;
    if (!!this.listing) {
      const color: IWeddingColor = !!this.listing.primaryColorId
        ? Globals.colors.find(c => c.id === this.listing.primaryColorId)
        : undefined;
      this.variationString = `${
        !!color ? color.name + (!!this.listing.size ? ', ' : '') : ''
      } ${!!this.listing.size ? this.listing.size : ''}`;
    }
  }

  isLiked() {
    return !!this.listing && !!User.doesLike[this.listing.id];
  }

  removeItem() {
    this.alertSrv
      .confirm('Remove item from cart?', '')
      .pipe(
        filter((didConfirm: boolean) => didConfirm),
        map(() => Globals.user.ShoppingCart.remove(this.listing.id)),
        take(1)
      )
      .subscribe();
  }

  clickEditNotes() {
    this.notesForm = this.formBuilder.group({
      customizationNotes: [
        this.item.customizationNotes,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(275)
        ]
      ]
    });
  }

  saveNotes(customizationNotes?: string) {
    this.notesForm = undefined;
    if (customizationNotes) {
      Globals.user.ShoppingCart.addUpdateItem({ ...this.item, customizationNotes });
    }
  }

  changeQuantity(qty: number) {
    Globals.user.ShoppingCart.addUpdateItem({ ...this.item, qty });
  }
}

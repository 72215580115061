import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { forkJoin, of, Subject } from 'rxjs';
import { debounceTime, filter, map, switchMap, takeUntil } from 'rxjs/operators';

import { Globals } from './../../classes';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-username-input',
  templateUrl: './username-input.component.html',
  styleUrls: ['./username-input.component.scss']
})
export class UsernameInputComponent implements OnInit, OnDestroy {
  public static maxUserNameLength = 25;
  maxLength = UsernameInputComponent.maxUserNameLength;
  usernameForm: FormGroup;
  private destroy$ = new Subject();
  @Output() username: EventEmitter<string | undefined> = new EventEmitter();
  isValidating = false;
  isTaken = false;
  isFormatValid = true;
  usernameDescription = `Enter a username others can identify you by on the site. ` +
    `Name must be between 3 and ${this.maxLength} characters and include no spaces or special characters.`;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private zone: NgZone
  ) { }

  ngOnInit() {
    const self = this;
    this.usernameForm = this.formBuilder.group({
      username: [undefined]
    });

    this.usernameForm.valueChanges.pipe(
      filter((val: { username: string; }) => {
        self.username.emit();
        self.isTaken = false;
        this.isFormatValid = !!val.username && val.username.length > 2 && !/[~` .!#@$%\^&*+=\-\[\]\\';,/{}()|\\":<>\?]/g.test(val.username);
        return this.isFormatValid;
      }),
      debounceTime(500),
      switchMap((val: { username: string; }) => {
        self.isValidating = true;
        const username = val.username.replace(/\\|\//g, '');
        return forkJoin([
          self.http.get(`${Globals.environment.apiUrl}users/validate-username/${username}`).pipe(map((r: { isValid: boolean; }) => r.isValid)),
          of(val.username)
        ]);
      }),
      filter(([isValid, username]) => {
        self.isValidating = false;
        if (!isValid) {
          self.isTaken = true;
        }
        return isValid;
      }),
      map(([isValid, username]) => {
        if (username === this.usernameForm.get('username').value) {
          self.zone.run(() => {
            self.username.emit(username);
          });
        }
      }),
      takeUntil(self.destroy$)
    ).subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}

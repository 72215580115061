<div *ngIf="isInIframe() === false">
  <div style="color:white;  max-width: 100vw!important;">
    <mat-divider style="margin-top: 40px;"></mat-divider>
    <ng-container *ngIf="!isAdminApp()">

      <div class="max-page-width footer-links" fxLayout="row wrap" fxLayoutAlign="center start"  fxLayoutGap="15px" fxLayout.xs="column"
        style="padding-top:25px;  max-width: 100vw!important; width: 100% !important;" ngStyle.xs="padding-left:15px;"
        >
        <div fxFlex.lt-md="35"  fxFlex.gt-sm="0 0 176px" fxLayout="column" fxLayoutGap="5px" style="margin-top:10px;" fxLayout.xs="column">
          <h3 class="mat-title">Sell</h3>
          <a routerLink="sell-on-wedzee" style="color:white !important; font-family: 'century_gothicregular' !important;">How to sell</a>
          <a routerLink="/seller-agreement" style="color:white !important; font-family: 'century_gothicregular' !important;">Seller Agreement</a>
          <a routerLink="/affiliate-program" style="color:white !important; font-family: 'century_gothicregular' !important;">Affiliate Program</a>
        </div>
        <div fxFlex.lt-md="35"  fxFlex.gt-sm="0 0 176px" fxLayout="column" fxLayoutGap="5px" style="margin-top:10px;" fxLayout.xs="column">
          <h3 class="mat-title">Shop</h3>
          <a routerLink="/how-it-works" style="color:white !important; font-family: 'century_gothicregular' !important;">How to buy</a>
          <a routerLink="order-lookup" style="color:white !important; font-family: 'century_gothicregular' !important;">Look up an order</a>
          <a routerLink="dispute/order-lookup" style="color:white !important; font-family: 'century_gothicregular' !important;">Dispute an order</a>
          <a *ngIf="isDevelopment && isCypress" (click)="signInWithToken()" style="color:white !important;">Sign in with
            custom Token</a>
        </div>
        <div fxFlex.lt-md="35"   fxFlex.gt-sm="0 0 176px" fxLayout="column" fxLayoutGap="5px" style="margin-top:10px;"fxLayout.xs="column">
          <h3 class="mat-title">About</h3>
          <a target="_blank" href="https://blog.wedzee.com" style="color:white !important;">Blog</a>
          <a routerLink="/about" style="color:white !important; font-family: 'century_gothicregular' !important;">About Wedzee</a>
          <a routerLink="/faq" style="color:white !important; font-family: 'century_gothicregular' !important;">FAQ</a>
          <a routerLink="/contact-us" style="color:white !important; font-family: 'century_gothicregular' !important;">Contact Us</a>
        </div>
        <div class="social-tags" fxFlex.lt-md="35"  fxFlex.gt-sm="0 0 176px" fxLayout="column" fxLayoutGap="5px"
          style="margin-top:10px;" fxLayout.xs="column">
          <div>
            <h3 class="mat-title">Follow Us</h3>
            <a [href]="socialUrls.facebook" target="_blank" style="font-family: 'century_gothicregular' !important;">
              <i class="fab fa-facebook-square"></i> Facebook
            </a>
            <a [href]="socialUrls.instagram" target="_blank" style="font-family: 'century_gothicregular' !important;">
              <i class="fab fa-instagram"></i> Instagram
            </a>
            <a [href]="socialUrls.twitter" target="_blank" style="font-family: 'century_gothicregular' !important;">
              <i class="fab fa-twitter"></i> Twitter
            </a>
            <a [href]="socialUrls.pinterest" target="_blank" style="font-family: 'century_gothicregular' !important;">
              <i class="fab fa-pinterest"></i> Pinterest
            </a>
          </div>
        </div>
      </div>
    </ng-container>


    <div class="footer-logo-area">
      <img routerLink="/" class="footerlogo" [src]="logoImgSrc">
    </div>

  </div>

  <div style="margin-top: 15px; margin-bottom:30px; height:10px !important; max-width: 100vw!important; width: 100% !important;" ngStyle.xs="margin-bottom:0px;height:45px;">
    <div class="spancopyright">
      Wedzee, LLC {{year}}
    </div>
    <div class="footer-sub-links">
      <a [routerLink]="['terms-of-service']" *ngIf="!isAdminApp()">Terms of Service</a>
      &nbsp;
      <a [routerLink]="['privacy-policy']" *ngIf="!isAdminApp()">Privacy Policy</a>
    </div>
    <br />
    <br />

  </div>
</div>

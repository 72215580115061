
<ng-container *ngIf="hasAllListings">
  <ng-container *ngIf="order as o">
    <div class="seller-order-container"  [ngSwitch]="!!isOnSellerPage">
      <div class="seller-order-header">
        <h2><b>Order Date:</b> {{o.createdTimestamp | date}}</h2>
      </div>
      <div class="seller-order-body">
        <div class="seller-order-body-top">
          <h2>
            #{{o.id | orderId}} - 
            <b>
              {{orderTracking.formattedStatus}}
              <wz-tooltip [matTooltip]="orderTracking.tooltipVerbiage" (click)="$event.stopPropagation()">
                <mat-icon style="font-size:1.2em; margin-left: 0; cursor: pointer; margin-top: 5px;" color="accent">help</mat-icon>
              </wz-tooltip>
            </b>
          </h2>
          <div class="hide-mobile">
            <ng-container *ngSwitchCase="true" [ngSwitch]="isAllCustom">
              <ng-container *ngSwitchCase="false"  [ngSwitch]="hasLabelsForItems(o)">
                <button 
                  class="btn btn-pink btn-sm" 
                  *ngSwitchCase="false"
                  [routerLinkAbsolute]="'seller/orders/get-labels/' + o.id + '/' + o.billToAddressZip"
                  (click)="$event.stopPropagation()"
                >
                  Get shipping labels
                </button>
                <button 
                  class="btn btn-pink btn-sm" 
                  *ngSwitchCase="true"
                  [routerLinkAbsolute]="'seller/orders/print-labels/' + o.id"
                  (click)="$event.stopPropagation()"
                >
                  Print shipping labels
                </button>
              </ng-container>
              <ng-container *ngSwitchCase="true">
                <h2>Ship all item(s) to buyer.</h2>
              </ng-container>
            </ng-container>
          </div>
        </div> 
        <div class="seller-order-body-bottom">
          <div class="seller-order-summery-row">
            <div class="seller-order-summery-details">
              <h2><b>Order Details</b> - <b>Total :</b> ${{o.totalsBilled.totalWithShippingAndTax | number:'1.2-2'}}</h2>
              <div [matMenuTriggerFor]="hideOrderMenu" (click)="$event.stopPropagation();" style="color: red; font-size: small; padding-top: 4px;" *ngIf="hasCancelledItems">
                <mat-icon style="font-size: 1em; margin-top: 6px;">error_outline</mat-icon>
                <span style="margin-left: -10px;">Has cancelled item(s)</span>
                <mat-menu #hideOrderMenu>
                  <button (click)="hideOrder(o.id)" mat-menu-item>
                    Hide this order
                  </button>
                </mat-menu>
              </div>
              <ng-container *ngSwitchCase="true">
                <div class="order-item-summery" *ngFor="let i of o?.items">
                  <div class="order-item-image">
                    <img matListAvatar style="border-radius: 0!important;" [src]="getThumbnailImg(i.listingId)" alt="Item image">
                  </div>
                  <div class="order-item-content">
                    <h4>{{ getListingName(i.listingId)}}</h4>
                    <p class="show-mobile">${{ i.taxjar.order_total_amount | number:'1.2-2' }}</p>
                    <p>Qty: {{ i.qty }}</p>
                    <p *ngIf="!!i.customizationNotes" [matTooltip]="i.customizationNotes">
                      <b>Customization notes</b>
                      {{i.customizationNotes}}
                  </div>
                  <div class="order-item-price hide-mobile">
                    <h4>${{ i.taxjar.order_total_amount | number:'1.2-2' }}</h4>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="seller-order-summery-action">
              <div class="show-mobile">
                <ng-container *ngSwitchCase="true" [ngSwitch]="isAllCustom">
                  <ng-container *ngSwitchCase="false"  [ngSwitch]="hasLabelsForItems(o)">
                    <button 
                      class="btn btn-pink btn-sm" 
                      *ngSwitchCase="false"
                      [routerLinkAbsolute]="'seller/orders/get-labels/' + o.id + '/' + o.billToAddressZip"
                      (click)="$event.stopPropagation()"
                    >
                      Get shipping labels
                    </button>
                    <button 
                      class="btn btn-pink btn-sm" 
                      *ngSwitchCase="true"
                      [routerLinkAbsolute]="'seller/orders/print-labels/' + o.id"
                      (click)="$event.stopPropagation()"
                    >
                      Print shipping labels
                    </button>
                  </ng-container>
                  <ng-container *ngSwitchCase="true">
                    <h2>Ship all item(s) to buyer.</h2>
                  </ng-container>
                </ng-container>
              </div>
              <button 
                class="btn btn-pink-border btn-sm" 
              >
                View Details
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- To Be Removed -->
      <!-- <div class="seller-order-footer">
        <h2>Show Order Details</h2>
      </div> -->
    </div>
  </ng-container>
</ng-container>

<!-- To Be Removed -->
<!-- <ng-container *ngIf="hasAllListings">
  <mat-card style="margin-top:15px; padding-right:0; margin-left:5px; margin-right: 5px; cursor: pointer;" class="text-left" *ngIf="order as o">
    <mat-card-content [ngSwitch]="!!isOnSellerPage" fxLayout="row" fxLayoutGap="15px;" style="line-height:1.5em;">
      <div fxFlex="calc(10% - 15px)">
        <br>
        <mat-icon>{{orderTracking.icon}}</mat-icon>
      </div>
      <div fxFlex="calc(30% - 15px)">
        <span style="color:gray; font-size:.8em;">Order Date</span>
        <div style="margin-top: 8px;">{{o.createdTimestamp | date}}</div>
        <div>
          {{orderTracking.formattedStatus}}
          <wz-tooltip [matTooltip]="orderTracking.tooltipVerbiage" (click)="$event.stopPropagation()">
            <mat-icon style="font-size:1.2em; margin-left: 0; cursor: pointer; margin-top: 5px;" color="accent">help</mat-icon>
          </wz-tooltip>
        </div>
        <ng-container *ngSwitchCase="true" [ngSwitch]="isAllCustom">
          <ng-container *ngSwitchCase="false"  [ngSwitch]="hasLabelsForItems(o)">
              <button
                *ngSwitchCase="false"
                ngStyle.xs="margin-top: 10px;"
                style="font-weight: bold;"
                [routerLinkAbsolute]="'seller/orders/get-labels/' + o.id + '/' + o.billToAddressZip"
                (click)="$event.stopPropagation()"
                mat-flat-button
                color="accent">
                Get shipping labels
              </button>

              <button
                *ngSwitchCase="true"
                ngStyle.xs="margin-top: 10px;"
                [routerLinkAbsolute]="'seller/orders/print-labels/' + o.id"
                (click)="$event.stopPropagation()"
                mat-flat-button
                color="accent">
                Print shipping labels
              </button>
          </ng-container>

          <ng-container *ngSwitchCase="true">
            <h3>Ship all item(s) to buyer.</h3>
          </ng-container>

        </ng-container>

        <div [matMenuTriggerFor]="hideOrderMenu" (click)="$event.stopPropagation();" style="color: red; font-size: small; padding-top: 4px;" *ngIf="hasCancelledItems">
          <mat-icon style="font-size: 1em; margin-top: 6px;">error_outline</mat-icon>
          <span style="margin-left: -10px;">Has cancelled item(s)</span>
          <mat-menu #hideOrderMenu>
            <button (click)="hideOrder(o.id)" mat-menu-item>
              Hide this order
            </button>
          </mat-menu>
        </div>
      </div>
      <div *ngSwitchCase="false" fxFlex="calc(30% - 15px)">
        <br>
        <div>
            {{o?.items.length}} {{o?.items.length == 1 ? 'item' : 'items'}}
        </div>
        <div>
            ${{o.totalsBilled.totalWithShippingAndTax | number:'1.2-2'}}
        </div>
      </div>
      <div *ngSwitchCase="true" fxFlex="calc(30% - 15px)">
        <span style="color:gray; font-size:.8em;">Item{{o?.items.length > 1 ? 's' : ''}}</span>
        <mat-list style="margin-top: -15px;">
          <mat-list-item *ngFor="let i of o?.items" style="margin-left: -15px;">
            <img matListAvatar style="border-radius: 0!important;" [src]="getThumbnailImg(i.listingId)" alt="Item image">
            <h3 matLine>
              {{ '(' + i.qty + ') ' +  getListingName(i.listingId)}}
            </h3>
            <p matLine class="form-hint">{{i.uiStatus}}</p>
            <div matLine *ngIf="!!i.customizationNotes" [matTooltip]="i.customizationNotes">
              <b>Customization notes</b>
              <br>
              <em>{{i.customizationNotes}}</em>
            </div>
          </mat-list-item>
        </mat-list>
      </div>
      <div fxFlex="calc(10% - 15px)" style="text-align:right;">
        <br>
        <mat-icon>chevron_right</mat-icon>
      </div>
    </mat-card-content>
  </mat-card>
</ng-container>
 -->

import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, of as observableOf, Subject } from 'rxjs';
import { catchError, filter, map, take, takeUntil } from 'rxjs/operators';

import { UsernameInputComponent } from '../../components/username-input/username-input.component';
import { AuthService } from '../../services/auth/auth.service';
import { LoggingService, wzCatchObservableError } from '../../services/logging/logging.service';
import { Globals } from './../../classes/global.class';
import { AlertService } from './../../services/alert/alert.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginDialogComponent implements OnInit, OnDestroy {
  private fileName = 'login.component.ts';
  forceIsRegistration = false;
  initCounter = 0;
  destroy$ = new Subject();
  isRegistration: boolean;
  isSellerGuard: boolean;
  isLoading: boolean;
  authForm: FormGroup;
  passwordRequirements = Globals.passwordRequirements;
  logoImgSrc = 'assets/wdz-logo.png';
  errorMsgs = {
    email: '',
    google: '',
    facebook: ''
  };

  env: any;

  public static open(isRegistration: boolean, matDialog: MatDialog, isSellerGuard?: boolean): Observable<boolean> {
    const dialogRef = matDialog.open(LoginDialogComponent, {
      hasBackdrop: true,
      width: '90%',
      maxWidth: '350px',
      position: { top: '100px' },
      data: {
        isRegistration,
        isSellerGuard: !!isSellerGuard
      }
    });

    return dialogRef.afterClosed();
  }

  constructor(
    public dialogRef: MatDialogRef<LoginDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authSrv: AuthService,
    private alertSrv: AlertService,
    private formBuilder: FormBuilder,
    private zone: NgZone,
    private loggingSrv: LoggingService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.env = Globals.environment;
    let initialIsRegistration = false;
    if (this.initCounter === 0) {
      initialIsRegistration = (!!this.data && !!this.data.isRegistration);
      this.forceIsRegistration = initialIsRegistration;
    }
    this.isRegistration = this.forceIsRegistration || initialIsRegistration;
    this.isSellerGuard = !!this.data && !!this.data.isSellerGuard;
    this.authForm = this.formBuilder.group({
      email: [undefined, [Validators.required, Globals.emailValidator(), Globals.noMaliciousCodeValidator()]],
      password: [undefined, [Validators.required, Globals.passwordValidator()]],
      username: [undefined, [Validators.required, Validators.minLength(3), Validators.maxLength(UsernameInputComponent.maxUserNameLength)]],
      registerRecaptcha: [undefined, Validators.required]
    });

    const emailCtrl = this.authForm.get('email');
    emailCtrl.valueChanges.pipe(
      filter((val) => val && val.indexOf(' ') > -1),
      map((val) => emailCtrl.setValue(val.trim())),
      takeUntil(this.destroy$)
    ).subscribe();

    if (!Globals.environment.production) {
      this.authForm.removeControl('registerRecaptcha');
    }

    if (!this.isRegistration) {
      this.authForm.removeControl('username');
      this.authForm.removeControl('registerRecaptcha');
    }
    this.initCounter++;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  close(isLoggedIn: boolean): void {
    const self = this;
    this.zone.run(() => {
      self.dialogRef.close(!!isLoggedIn);
    });
  }

  google() {
    const self = this;
    self.isLoading = true;
    const startTime = new Date().getTime();
    return self.authSrv.googleLogin().pipe(
      wzCatchObservableError(this.fileName, 'google()', true),
      map(() => {
        if (!self.isRegistration && !!Globals.user.displayName) {
          this.alertSrv.successToast(`Welcome back${!!Globals.user.displayName ? ', ' + Globals.user.displayName.split(' ')[0] : ''}!`);
        } else if (!self.isRegistration && !!Globals.user && !!Globals.user.email) {
          this.alertSrv.successToast(`Welcome back!`);
        }
        self.close(true);
        self.isLoading = false;
        this.loggingSrv.trackTiming('google_login', startTime);
      }),
      catchError((error: any) => {
        self.errorMsgs.google = self.getErrorMessage(error, 'google');
        this.zone.run(() => self.isLoading = false);
        return observableOf(undefined);
      }),
    ).subscribe();
  }

  toggleAuthType() {
    this.forceIsRegistration = !this.forceIsRegistration;
    this.ngOnInit();
  }

  facebook() {
    const self = this;
    self.isLoading = true;
    const startTime = new Date().getTime();
    return self.authSrv.facebookLogin().pipe(
      wzCatchObservableError(this.fileName, 'facebook()', true),
      filter(() => !self.errorMsgs.facebook),
      map(() => {
        if (!self.isRegistration && !!Globals.user && !!Globals.user.email) {
          this.alertSrv.successToast(`Welcome back${!!Globals.user && !! Globals.user.displayName ? ', ' + Globals.user.displayName.split(' ')[0] : ''}!`);
        }
        self.close(true);
        self.isLoading = false;
        this.loggingSrv.trackTiming('facebook_login', startTime);
      }),
      catchError((error: any) => {
        this.zone.run(() => self.isLoading = false);
        self.errorMsgs.facebook = self.getErrorMessage(error, 'facebook');
        return observableOf(undefined);
      }),
    ).subscribe();
  }

  submitAuthForm() {
    const self = this;
    const startTime = new Date().getTime();
    self.isLoading = true;
    self.errorMsgs.email = undefined;
    const email = self.authForm.get('email').value;
    const password = self.authForm.get('password').value;
    const username = self.isRegistration ? self.authForm.get('username').value : undefined;
    let loginObs: () => Observable<any> = () => self.authSrv.passwordLogin(email, password, true);
    if (self.isRegistration) {
      loginObs = () => self.authSrv.createAccountWithEmailAndPassword(email, password, username);
    }

    loginObs().pipe(
      take(1),
      catchError((error: any) => {
        self.errorMsgs.email = self.getErrorMessage(error);
        self.isLoading = false;
        return observableOf(undefined);
      }),
      filter(() => !self.errorMsgs.email),
      map(() => {
        self.close(true);
        if (self.isRegistration && !!Globals.user && !!Globals.user.email) {
          self.alertSrv.alert('Welcome to Wedzee!', `We\'ve sent you an email. Please open the email and click the verification link to confirm your email address.`);
        } else if (!!Globals.user && !!Globals.user.email) {
          this.alertSrv.successToast(`Welcome back!`);
        }
        self.isLoading = false;
        this.loggingSrv.trackTiming('password_login', startTime);
      })
    ).subscribe();
  }

  getErrorMessage(error: any, method?: string): string {
    switch (error.code) {
      case 'auth/user-not-found':
        return 'Invalid email and password combination. Are you sure you created an account under this email?';
      case 'auth/wrong-password':
        return 'Invalid username and password combination. Did you forget your password?';
      case 'auth/email-already-in-use':
        return 'This email address is taken. Did you already create an account?';
      case 'auth/account-exists-with-different-credential':
        if (method === 'facebook' || method === 'google') {
          return `There\'s already an account created for that ${method} account\'s email address.`;
        }
        return 'There\'s already an account associated with that email address.';
      case 'auth/network-request-failed':
        return 'Slow or poor network connection. Please try again later.';
      case 'auth/user-disabled':
        return `Your account has been disabled. Please contact ${Globals.environment.supportEmail}`;
      case 'auth/popup-closed-by-user':
        location.reload();
        break;
      default:
        console.warn('NEW FIREBASE AUTH ERROR: Need to write a message for it - ', error);
        return 'An unexpected error has occured.';
    }
    // ADD THIS: auth/network-request-failed
  }

}

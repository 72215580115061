<form
  *ngIf="!!searchForm"
  [formGroup]="searchForm"
  fxLayout="row"
  fxLayoutAlign="center"
  fxLayoutGap="8px">
  <mat-form-field
  style="width:calc(100% - 109px)!important;"
    appearance="outline" class="search-form-field mini-input"
    [ngClass]="mobile ? 'full-width' : ''"
    (click)="searchBoxClicked$.next()"
  >
    
  <input class="search-input searchtxt" [matAutocomplete]="searchAutocomplete" type="search" formControlName="searchText" matInput placeholder="Search anything">
  </mat-form-field>
  <button class="wdz-btn" mat-flat-button (click)="$event.stopPropagation(); clickSearchBtn()">Search</button>
</form>
<mat-autocomplete #searchAutocomplete [panelWidth]="searchPanelWidth">
  <mat-option
    *ngFor="let category of searchCategories$ | async"
    (click)="clearForm()"
    [routerLink]="['category', category.id]"
    routerLinkActive="active-link"
    class="wz-menu-item"
  >
    <b>{{category.name}}</b>
  </mat-option>
</mat-autocomplete>

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { Globals } from '../../classes';

declare function getAffiliatlyCookieData(): any;

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.scss']
})
export class ShareDialogComponent implements OnInit {

  title: string;
  url: string;

  facebookUrl: string;
  twitterUrl: string;
  instagramUrl: string;
  pinterestUrl: string;

  isIos: boolean;

  public static open(title: string, url: string, matDialog: MatDialog): Observable<string> {
    const dialogRef = matDialog.open(ShareDialogComponent, {
      hasBackdrop: true,
      width: '90%',
      minHeight: '200px',
      maxWidth: '350px',
      position: { top: '400px' },
      data: {
        title,
        url
      }
    });

    return dialogRef.afterClosed().pipe(
      take(1),
      filter((res: any) => !!res)
    );
  }

  constructor(
    public dialogRef: MatDialogRef<ShareDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    console.log(this.data);
    this.loadPinterestJs();
    this.isIos = Globals.isIos;
    const { title, url } = this.data;
    this.title = title;

    const cookieData = getAffiliatlyCookieData();
    if (cookieData.aff_uid === '2' || localStorage.getItem('aff_uid') === '2') {
      this.url = 'https://marketplace.thebudgetsavvybride.com?page=' + url;
    } else if (cookieData.aff_uid === '7' || localStorage.getItem('aff_uid') === '7') {
      this.url = 'https://cbtcorp.net/wedding/abg.html?page=' + url;
    } else {
      this.url = (Globals.environment.development ? 'https://wedzee-dev.firebaseapp.com/' : Globals.environment.siteUrl) + url;
    }

    const encodedUrl = encodeURI(this.url);
    this.facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${this.url}`;
    this.twitterUrl = `http://twitter.com/share?text=${encodeURI('Wedzee - ' + this.title)}&url=${this.url}`;
    this.pinterestUrl = `https://www.pinterest.com/pin/create/button/?url=${encodedUrl}`;
  }

  close() {
    this.dialogRef.close();
  }

  loadPinterestJs() {
    const url = 'https://assets.pinterest.com/js/pinit.js';
    const scriptEl = document.createElement('script');
    scriptEl.setAttribute('src', url);
    document.head.appendChild(scriptEl);
    scriptEl.onload = () => {
      // Pinterest javascript is loaded
    };
  }
}
